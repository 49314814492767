<template>
  <div class="policy_container" style="text-align:center">
    <h1>COMING SOON</h1>
    Visit our <a target="_blank" href="https://www.facebook.com/CrazySignLadiesKY">Facebook</a> and <a target="_blank" href="https://www.instagram.com/crazysignladiesky">Instagram</a> pages for more product depictions in the interim!
  </div>
</template>

<style scoped>
    a {
        color:#b768f6;
    }
</style>
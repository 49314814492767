<template>
  <div class="about">
    <div>  
      <div class="info">
        <div>Yard Signs</div>
        <p>502-694-CARD(2273)</p>
        <a href="mailto:info@crazysignladies.com">info@crazysignladies.com</a>
      </div>    
    </div>
  </div>
</template>

<style scoped>
  .about {
    text-align:center;
  }
</style>
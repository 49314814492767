<template>
  <div class="ql-editor" v-html="pageContent">
    
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
   setup() {
    
  },
  data() {
    return {
      pageContent:"<b> Loading </b>"
    }
  },
  mounted(){
       axios.get(`https://api.crazysignladies.com/content/Policies`).then(response => {
            this.pageContent = response.data.Item.PageContent;
          });
    
  }
})
</script>


<style scoped>

</style>
<template>
<div>
  <div class="ql-editor"  v-html="pageContent">
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
   setup() {
    
  },
  data() {
    return {  
      pageContent:"<b> Loading </b>"
    }
  },
  mounted(){
       axios.get(`https://api.crazysignladies.com/content/About`).then(response => {
            this.pageContent = response.data.Item.PageContent;
          });
    
  }
})
</script>


<style scoped>
  text {
      font-family: 'Wild And Crazy Medium';
      font-size:38px;    
  }

  path {
    fill: transparent;  
  }

  .info {
    
  }
  .svgHolder{
    width: 50vw;
    height: 40vh;
    margin:auto;
  }
</style>